import { createSlice } from '@reduxjs/toolkit';
import { createUserDentist, deleteUserDentist, getDentist, getUsersDentists, updateUserDentist, } from './dentistThunk';
const initialState = {
    data: [],
    allUsersDentist: [],
    userDentist: {},
    loading: false,
    createUserDentistStatus: false,
    updateUserDentistStatus: false,
    deleteUserDentistStatus: false,
    error: null,
    total: 0,
};
const slice = createSlice({
    name: 'dentist',
    initialState,
    reducers: {
        setDentistToUpdate: (state, action) => {
            state.userDentist = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getDentist.pending, (state) => {
            state.loading = true;
        })
            .addCase(getDentist.fulfilled, (state, { payload }) => {
            state.loading = false;
            state.data = payload.docs;
            state.total = payload.meta.totalDocs;
        })
            .addCase(getDentist.rejected, (state) => {
            state.error = true;
            state.loading = false;
        })
            .addCase(getUsersDentists.pending, (state) => {
            state.loading = true;
        })
            .addCase(getUsersDentists.fulfilled, (state, { payload }) => {
            state.loading = false;
            state.allUsersDentist = payload.docs;
            state.total = payload.meta.totalDocs;
        })
            .addCase(getUsersDentists.rejected, (state) => {
            state.error = true;
            state.loading = false;
        })
            .addCase(createUserDentist.pending, (state) => {
            state.createUserDentistStatus = true;
        })
            .addCase(createUserDentist.fulfilled, (state) => {
            state.createUserDentistStatus = false;
        })
            .addCase(createUserDentist.rejected, (state) => {
            state.error = true;
            state.createUserDentistStatus = false;
        })
            .addCase(updateUserDentist.pending, (state) => {
            state.updateUserDentistStatus = true;
        })
            .addCase(updateUserDentist.fulfilled, (state) => {
            state.updateUserDentistStatus = false;
        })
            .addCase(updateUserDentist.rejected, (state) => {
            state.error = true;
            state.updateUserDentistStatus = false;
        })
            .addCase(deleteUserDentist.pending, (state) => {
            state.deleteUserDentistStatus = true;
        })
            .addCase(deleteUserDentist.fulfilled, (state) => {
            state.deleteUserDentistStatus = false;
        })
            .addCase(deleteUserDentist.rejected, (state) => {
            state.error = true;
            state.deleteUserDentistStatus = false;
        });
    },
});
// Reducer
export default slice.reducer;
// Actions
export const { setDentistToUpdate } = slice.actions;
